<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems" toolbar-enabled>
    <template #toolbar>
      <filters-tags :value="filters" @input="onFilter" />
      <v-spacer />
      <cz-button
        :title="$t('customer.digitalArchive.export')"
        :icon-src="mdiDownloadCircleOutline"
        color="info"
        text
        class="mx-4"
        @click="onExport"
      />
      <v-badge
        :value="hasActiveFilters(filters)"
        left
        color="buttonSecondary"
        :offset-y="14"
        :offset-x="20"
        :content="activeFiltersCount(filters)"
      >
        <cz-button
          :title="$t('common.filters')"
          color="info"
          text
          :icon-src="
            hasActiveFilters(filters) ? mdiFilterCheckOutline : mdiFilterOutline
          "
          @click="showFiltersBar = true"
        />
      </v-badge>
    </template>

    <cz-data-table v-bind="dataTable" :options.sync="dataTable.options">
      <template #item.createdAt="{ item }">
        {{ formatDateToDisplay(item.createdAt) }}
      </template>
      <template #item.objectCreationDate="{ item }">
        {{ formatDateToDisplay(item.objectCreationDate) }}
      </template>

      <template #item.createdBy="{ item }">
        <user-info-menu
          :user="item.objectCreatedByUser"
          v-if="item.objectCreatedByUser"
        />
      </template>

      <template #item.url="{ item }">
        <cz-button
          :href="item.url"
          text
          :title="$t('common.viewDocument')"
          target="_blank"
          :icon-src="mdiOpenInNew"
          color="info"
          class="font-weight-semibold"
        />
      </template>
    </cz-data-table>
    <filters-bar
      v-if="filters"
      v-model="showFiltersBar"
      @close="showFiltersBar = false"
      @on-filter="onFilter"
      :account-id="accountId"
      :filter-fields="filters"
    />
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
import { mapFields } from 'vuex-map-fields';
import FiltersBar from '@/domain/shared/components/FiltersBar.vue';
import FiltersTags from '@/domain/shared/components/FiltersTags.vue';
import { CzDataTable, CzButton } from '@/components';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import {
  mdiInformationOutline,
  mdiOpenInNew,
  mdiCalendarRangeOutline
} from '@mdi/js';
import { getFiles, getVendors } from '@/core/api';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import {
  mdiNumeric,
  mdiFilterOutline,
  mdiFilterCheckOutline,
  mdiAccountCardOutline,
  mdiDownloadCircleOutline,
  mdiAccountOutline
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import {
  filtersWithValues,
  hasActiveFilters,
  formatFiltersForServer,
  activeFiltersCount
} from '@/shared/services/filters.service';
import { format } from 'date-fns';
export default {
  name: 'DigitalArchivePage',
  components: {
    ContentLayout,
    CzDataTable,
    FiltersBar,
    FiltersTags,
    CzButton,
    UserInfoMenu
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.digitalArchiveTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    formatFiltersForServer() {
      const query = {};

      Object.entries(this.filterFields).forEach(([key, value]) => {
        console.log('key ' + key + ' value: ' + value);
      });

      return query;
    }
  },
  created() {
    this.initializeFilters();
  },
  data() {
    return {
      mdiDownloadCircleOutline,
      mdiFilterOutline,
      mdiFilterCheckOutline,
      mdiInformationOutline,
      showFiltersBar: false,
      mdiOpenInNew,
      accountCopy: null,
      selectedLogoFile: null,
      selectedLogoSrc: null,
      filters: [],
      vendorSearch: {
        loading: false,
        items: []
      },
      usersSearch: {
        loading: false,
        items: []
      },
      initialHeight: 0,
      filterFields: {
        documentNumber: '',
        documentType: '',
        vendorId: '',
        createdAt: null,
        objectCreationDate: null,
        userId: '',
        objectNumber: ''
      },
      loading: false,
      dataTable: {
        loading: false,
        options: {},
        columns: [
          {
            text: this.$t('customer.digitalArchive.documentId'),
            value: 'documentNumber'
          },
          {
            text: this.$t('customer.digitalArchive.documentType'),
            value: 'documentSignatureTypeText'
          },
          {
            text: this.$t('customer.digitalArchive.businessNumber'),
            value: 'vendorId'
          },
          {
            text: this.$t('customer.digitalArchive.documentDate'),
            value: 'createdAt'
          },
          {
            text: this.$t('customer.digitalArchive.objectCreationDate'),
            value: 'objectCreationDate'
          },

          {
            text: this.$t('customer.digitalArchive.objectNumber'),
            value: 'objectNumber'
          },
          {
            text: this.$t('customer.digitalArchive.objectType'),
            value: 'objectTypeText'
          },
          {
            text: this.$t('customer.digitalArchive.createdBy'),
            value: 'createdBy'
          },
          {
            text: '',
            value: 'url'
          }
        ],
        items: []
      }
    };
  },
  methods: {
    formatDateToDisplay,
    filtersWithValues,
    hasActiveFilters,
    activeFiltersCount,
    initializeFilters() {
      const initialFilters = [
        {
          fieldname: 'documentNumber',
          label: this.$t('customer.digitalArchive.documentId'),
          type: 'input',
          inputType: 'number',
          icon: mdiNumeric,
          value: ''
        },
        {
          fieldName: 'objectType',
          label: this.$t('customer.digitalArchive.documentType'),
          type: 'autocomplete',
          itemText: 'text',
          itemValue: 'value',
          itemsHandler: () => {
            return [
              {
                text: this.$t('customer.digitalArchive.docTypes.order'),
                value: 'order'
              },
              {
                text: this.$t('customer.digitalArchive.docTypes.submission'),
                value: 'submission'
              }
            ];
          },
          value: ''
        },
        {
          fieldname: 'vendorId',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        },
        {
          fieldname: 'createdAt',
          label: this.$t(
            'customer.digitalArchive.filters.documentCreationDate'
          ),
          type: 'datepicker',
          value: [],
          placeholder: 'dd/MM/yyyy - dd/MM/yyyy',
          // rules: 'date_format:yyyy-MM-dd',
          icon: mdiCalendarRangeOutline,
          multiple: false,
          operator: 'bt',
          props: {
            maxDate: format(new Date(), 'yyyy-MM-dd')
          }
        },
        {
          fieldname: 'objectCreationDate',
          label: this.$t('customer.digitalArchive.filters.objectCreationDate'),
          type: 'datepicker',
          value: [],
          placeholder: 'dd/MM/yyyy - dd/MM/yyyy',
          icon: mdiCalendarRangeOutline,
          multiple: false,
          operator: 'bt',
          props: {
            maxDate: format(new Date(), 'yyyy-MM-dd')
          }
        },
        {
          fieldname: 'objectNumber',
          label: this.$t('customer.digitalArchive.objectNumber'),
          type: 'input',
          inputType: 'number',
          icon: mdiNumeric,
          value: ''
        },
        {
          fieldname: 'userId',
          label: this.$t('customer.digitalArchive.createdBy'),
          type: 'users',
          items: [],
          icon: mdiAccountOutline,
          value: ''
        }
      ];

      this.filters = cloneDeep(initialFilters);
    },
    async loadData(reset = false) {
      if (reset) {
        this.dataTable.options.page = 1;
      }

      // get all submissions for list of orders
      const { itemsPerPage, page, sortBy, sortDesc } = this.dataTable.options;

      const $skip = (page - 1) * itemsPerPage;

      try {
        const query = {
          accountId: this.accountId,
          isSigned: true,
          $populate: ['objects'],
          $limit: itemsPerPage,
          $skip
        };

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        if (this.filters?.length) {
          const formattedFilters = formatFiltersForServer(
            cloneDeep(this.filters)
          );
          formattedFilters.forEach((field) => {
            query[field.fieldname] = field.value;
          });
        }

        this.dataTable.loading = true;
        const { data } = await getFiles(query);
        this.dataTable.items = data;
      } finally {
        this.dataTable.loading = false;
      }
    },
    async onVendorsSearch(value) {
      if (!value) {
        this.vendors = [];
        return;
      }
      try {
        this.vendorSearch.loading = true;
        this.vendorSearch.items = await getVendors({
          search: value,
          isTest: false
        });
      } finally {
        this.vendorSearch.loading = false;
      }
    },
    onFilter(filters) {
      this.showFiltersBar = false;
      if (!isEqual(this.filters, filters)) {
        this.filters = cloneDeep(filters);
        this.loadData(true);
      }
    },
    onExport() {}
  },
  watch: {
    filterFields: {
      deep: true,
      handler() {
        this.loadData();
      }
    },
    'dataTable.options': {
      handler() {
        this.loadData(true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-input {
  max-width: 350px;
  min-width: 250px;
}

.date-input {
  width: 250px;
}
</style>
