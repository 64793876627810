<template>
  <div class="d-flex flex-wrap align-center" style="gap: 1rem">
    <cz-chip
      v-for="item of filtersWithValues(value)"
      :key="item.fieldname"
      removeable
      color="buttonSecondary"
      @remove="onTagRemove(item)"
    >
      <div class="d-flex" style="column-gap: 2px">
        <span>{{ item.label }}:</span>
        <span class="font-weight-semibold">{{
          formatItemForDisplay(item)
        }}</span>
      </div>
    </cz-chip>
  </div>
</template>

<script>
import { filtersWithValues } from '@/shared/services/filters.service';
import { CzChip } from '@/components';
import cloneDeep from 'lodash.clonedeep';
import { format } from 'date-fns';

export default {
  name: 'FiltersTags',
  components: {
    CzChip
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filters: []
    };
  },
  methods: {
    filtersWithValues,
    onTagRemove(item) {
      const _value = cloneDeep(this.value);
      const field = _value.find(
        (filterItem) => filterItem.fieldname === item.fieldname
      );
      if (field) {
        field.value = '';
      }

      this.$emit('input', _value);
    },
    formatItemForDisplay(item) {
      switch (item.type) {
        case 'vendors':
          return item.value?.businessName;
        case 'users':
          return item.value?.fullName;
        case 'datepicker':
          if (Array.isArray(item.value)) {
            if (item.value.length === 2) {
              return `${format(
                new Date(item.value[0]),
                'dd/MM/yyyy'
              )} - ${format(new Date(item.value[1]), 'dd/MM/yyyy')}`;
            } else {
              return format(new Date(item.value[0]), 'dd/MM/yyyy');
            }
          } else {
            return format(item.value, 'dd/MM/yyyy');
          }
        default:
          return item.value;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
